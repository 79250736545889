import {v4 as uuidV4} from "uuid";
import { getConfigurationValue } from "../api/components/download";

export function getIMEI() {
  if (process.browser && typeof window.localStorage !== 'undefined') {
    let IMEI = localStorage.getItem('IMEI');
    if (IMEI === null) {
      IMEI = uuidV4();
      localStorage.setItem('IMEI', IMEI);
    }

    return IMEI;
  }
  return '';
}

// 获取渠道
export function appCode() {
  const referrer = document.referrer.split('?')[1]
  const obj = {}
  if (referrer) {
    const referrArr = referrer.split('&');
    for (let i = 0; i < referrArr.length; i++) {
      obj[referrArr[i].split('=')[0]] = referrArr[i].split('=')[1]
    }
  }
  const nowParams = location.search.replace(/^\?/, '')
  const objParams = nowParams.split('&');
  const obj2 = {}
  for (let i = 0; i < objParams.length; i++) {
    obj2[objParams[i].split('=')[0]] = objParams[i].split('=')[1]
  }
  return Object.assign({}, obj, obj2)
}

// 获取IP信息
export async function getIpConfig() {
  const ipInfo = sessionStorage.getItem("IP");
  if(ipInfo && ipInfo!== '{}') {
    const location = JSON.parse(ipInfo);
    const arr = [location.country, 0, location.province, location.city, location.isp]
    return {
      Ip: location.ip,
      IpAddr: arr.join('|')
    }
  } else {
    try {
      const res = await getConfigurationValue({key: 'IPAPI'});
      if(!res.Data) return {}
      // const ipResponse = await fetch('https://api.ipdatacloud.com/v2/query?key=1032b765959d11eeaa2b00163e25360e');
      const ipResponse = await fetch('https://api.ipdatacloud.com/v2/query?key=' + res.Data);
      const ipData = await ipResponse.json();
      const location = ipData.data.location || {};
      sessionStorage.setItem("IP", JSON.stringify(location));
      const arr = [location.country, 0, location.province, location.city, location.isp]
      return {
        Ip: location.ip,
        IpAddr: arr.join('|')
      }
    } catch(e) {
      return {}
    }
  }
}