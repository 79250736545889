let host = location.host
let socketUrl = 'wss://'
if (host.indexOf('localhost') > -1 || host.indexOf('.lan') > -1 || host.indexOf('8080') > -1) {
  // 线上
  // host = 'zhiborlc.xsyitou.com'
  // 测试
  host = 'manage.huiadmin.lan'
  socketUrl = 'ws://'
}
const config = {
  platid: 1, // 使用平台
  socketUrl: socketUrl + host + '/apis/websock', // socket地址
  apiUrl: {
    production: {
      baseUrl: 'https://www.rlcproltd.net/api/proxy'
    },
    development: {
      baseUrl: 'https://testwww.rlc525.com/api/proxy'
    },
  }
}
export default config

