import {v4 as uuidV4} from "uuid";
/**
 * 这是工具类文件，存放一些项目通用的方法
 */

// 设置session
export function setSessionStorage(key, value) {
  sessionStorage.setItem(key, JSON.stringify(value))
}
// 获取session
export function getSessionStorage(key) {
  return sessionStorage.getItem(key)
}

export function getIMEI() {
  if (process.browser && typeof window.localStorage !== 'undefined') {
    let IMEI = localStorage.getItem('IMEI');
    if (IMEI === null) {
      IMEI = uuidV4();
      localStorage.setItem('IMEI', IMEI);
    }

    return IMEI;
  }
  return '';
}