import {
  getRoomList,
  getRoomInfo,
  getRoomHistoryChatRecord,
  getCurrentRoomUser,
  youkeLogin,
  handleForbidEvent,
  getUserListByManagerId,
  getUserPrivateChatHistoryListById,
  getRoomDefaultVideoInfo,
  getDicts,
  getTeacherInfo,
  getBnotes,
  getLiving,
  loginSetTime,
  isguestmessage,
  getDisplayNum,
  getDistributecustomer,
  getNewRoomHistory,
  getTimetable,
  verifypwd,
  Iswelcome,
  getPlayBack,
  nickAvatar,
} from "../../api/home";
import config from "../../config/index";
import { Message } from "element-ui";
import { setSessionStorage, getSessionStorage } from "../../utils/tool";

const state = {
  isplay: false, // 讲师是否在上播状态
  showRoomList: false, // 是否展示房间列表
  showUserList: false, // 是否显示用户列表
  roomInfo: JSON.parse(getSessionStorage("roomInfo"))
    ? JSON.parse(getSessionStorage("roomInfo"))
    : {}, // 当前房间的信息
  roomList: [], // 房间列表
  currentRoomUserList: [], // 当前房间的在线用户
  roomUserTotal: 0, // 当前房间在线人数
  roomHistoryChatRecordList: [], // 房间历史聊天记录
  roomGiftList: [], //直播间用户送出礼物列表
  socket: {}, // socket
  defaultVideoInfo: {}, // 默认视频信息
  privateUserInfo: {}, // 私聊对象（当前正在聊天中的用户）的信息
  privateChatHistoryList: [], // 私聊记录(当前私聊中的用户)
  privateUserList: [], // 私聊用户列表
  stopWordsUserInfo: {}, // 禁言用户信息
  // 弹出层控制参数
  dialogObj: {
    dialogVisible: false,
    isCloseIcon: true, // 是否显示关闭按钮
    type: 1, // 弹框区域要展示的内容 1、登录(width: 500px) 2、禁言(width: 500px) 3、私聊(width: 650px)
    width: "500px", // 弹出层宽度登录(width: 500px) 禁言(width: 500px) 私聊(width: 650px)
  },
  dialogObj2: {
    dialogVisible: false,
    isCloseIcon: true, // 是否显示关闭按钮
    type: 1, // 弹框区域要展示的内容 1、登录(width: 500px) 2、禁言(width: 500px) 3、私聊(width: 650px)
    width: "500px", // 弹出层宽度登录(width: 500px) 禁言(width: 500px) 私聊(width: 650px)
  },
  dialogObj3: {
    dialogVisible: false,
    isCloseIcon: true, // 是否显示关闭按钮
    type: 1, // 弹框区域要展示的内容 1、登录(width: 500px) 2、禁言(width: 500px) 3、私聊(width: 650px)
    width: "500px", // 弹出层宽度登录(width: 500px) 禁言(width: 500px) 私聊(width: 650px)
  },
  dialogObj4: {
    dialogVisible: false,
    isCloseIcon: true, // 是否显示关闭按钮
    width: "500px", // 弹出层宽度登录(width: 500px) 禁言(width: 500px) 私聊(width: 650px)
    gq: true,
  },
  forbidtimelist: [], // 禁言时长列表
  forbidreasonlist: [], // 禁言原因列表
  roomset: {}, // 管理系统设置的聊天室设置
  videoPlayerConfig: {
    playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
    autoplay: true, // 如果true,浏览器准备好时开始回放。
    muted: true, // 默认情况下将会消除任何音频。
    loop: false, // 导致视频一结束就重新开始。
    preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
    language: "zh-CN",
    aspectRatio: "1:1", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
    fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
    sources: [
      {
        type: "", // 这里的种类支持很多种：基本视频格式、直播、流媒体等 video/ogg video/webm video/mp4
        src: "", // url地址
      },
    ],
    hls: true, // 如果是播放m3u8必须加（需注释掉techOrder,不然会有报错）
    // techOrder: ['flash'], // 播放rtmp必须加
    poster: "", // 你的封面地址
    notSupportedMessage: "暂无视频", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
    controlBar: {
      timeDivider: true,
      durationDisplay: true,
      remainingTimeDisplay: false,
      fullscreenToggle: true, // 全屏按钮
    },
  },
  ckplayerObject: {
    container: "#ckplayerVideo", // “#”代表容器的ID，“.”或“”代表容器的class
    width: "100%", // 宽度，也可以支持百分比(不过父元素宽度要有)
    height: 690, // 高度，也可以支持百分比
    variable: "player", // 该属性必需设置，值等于下面的new chplayer()的对象
    autoplay: true, // 自动播放
    live: false, // 是否直播流
    video: "", // 视频地址(必填)
  },
  player: {}, // ckplayer实例对象
  ishomePrivateBtn: false, // 是否首页私聊按钮触发
  roomtid: 0, // 当前进入的房间的老师id
  isatuoscroll: true, // 公聊窗口记录是否自动滚动
  teacherInfo: "", // craig -- 老师信息和课程信息
  bnotes: "", // craig -- 课堂笔记列表
  selectTree: "", // craig -- 控制互动消息筛选
  craigPlay: false, // craig -- 进入页面给roomlist组件传递需要播放
  livingInfo: "", // craig -- 获取当前正在直播的信息
  // craig -- 登录提示间隔时间
  tipTime: {
    num: "123",
    minute: "123",
  },
  // craig -- 是否禁言
  isguestmessage: false,
  // craig -- 保存用户注册的密码
  userPwd: "",
  // craig -- 控制弹窗
  controlTimer: false,
  // craig -- 聊天记录显示条数
  displayNum: 0,
  // craig -- 分配的客服信息
  distributecustomer: "",
  // craig -- 是否显示客服图表上的小红点
  showRed: false,
  // craig -- 判断普通用户是否可以@别人
  permissions: "",
  // craig -- 新增房间聊天记录
  newRoomHistory: "",
  // craig -- 控制右侧菜单栏
  rightMenu: false,
  // craig -- 控制课表显示
  timeTable: false,
  // craig -- 课表内容
  timeTableContent: "",
  // craig -- 控制禁言弹窗
  noSpeak: false,
  // craig -- 控制vip房间弹窗
  isVip: false,
  // craig -- 记录用户进入的vip房间id
  vipRoom: false,
  // craig -- 密码是否正确
  pwdRight: false,
  // craig -- 控制侧边栏的状态
  packOrDevelop: false,
  // craig -- 控制刷新video
  isReload: false,
  // craig -- 房间是否满足条件
  isComming: false,
  // craig -- 控制是否展示无直播的广告
  controlNoLive: true,
  // craig -- 获取老师回放视频列表
  teacherPlayBack: true,
  // 用户新消息数
  customMsgTotal: 0,
};

const mutations = {
  set_isplay(state, bool) {
    state.isplay = bool;
  },
  set_player(state, obj) {
    state.player = obj;
  },
  set_ckplayerObject(state, option) {
    state.ckplayerObject = Object.assign({}, state.ckplayerObject, option);
  },
  set_showroomlist(state, bool) {
    state.showRoomList = bool;
  },
  set_showuserlist(state, bool) {
    state.showUserList = bool;
  },
  set_roominfo(state, detail) {
    state.roomInfo = detail;
  },
  set_roomList(state, list) {
    state.roomList = list;
  },
  set_currentroomuserlist(state, list) {
    state.currentRoomUserList = list;
  },
  set_roomusertotal(state, num) {
    state.roomUserTotal = num;
  },
  set_roomhistorylist(state, list) {
    state.roomHistoryChatRecordList = list;
  },
  set_roomGiftList(state, list) {
    state.roomGiftList = list;
  },
  set_socket(state, socket) {
    state.socket = socket;
  },
  set_dialogobj(state, obj) {
    state.dialogObj = Object.assign({}, state.dialogObj, obj);
  },
  // set_dialogobj2(state, obj) {
  //   state.dialogObj2 = Object.assign({}, state.dialogObj2, obj)
  // },
  set_dialogobj3(state, obj) {
    state.dialogObj3 = Object.assign({}, state.dialogObj3, obj);
  },
  set_stopWordsUserInfo(state, info) {
    state.stopWordsUserInfo = info;
  },
  set_forbidtimelist(state, list) {
    state.forbidtimelist = list;
  },
  set_forbidreasonlist(state, list) {
    state.forbidreasonlist = list;
  },
  set_privateUserInfo(state, info) {
    state.privateUserInfo = info;
  },
  set_privateUserList(state, list) {
    state.privateUserList = list;
  },
  set_privateChatHistoryList(state, list) {
    state.privateChatHistoryList = list;
  },
  set_defaultVideoInfo(state, info) {
    state.defaultVideoInfo = { ...info };
  },
  set_videoPlayerConfig(state, source) {
    state.videoPlayerConfig = { ...state.videoPlayerConfig, ...source };
  },
  set_roomset(state, list) {
    const detail = {};
    list.forEach((item) => {
      detail[item.label] = item.value;
    });
    state.roomset = detail;
  },
  set_ishomePrivateBtn(state, bool) {
    state.ishomePrivateBtn = bool;
  },
  set_roomtid(state, id) {
    state.roomtid = id;
  },
  set_isatuoscroll(state, bool) {
    state.isatuoscroll = bool;
  },
  set_token(state, token) {
    state.token = token;
  },
  set_userInfo(state, info) {
    state.userInfo = info;
  },
  // craig--newAdd
  set_teacherInfo(state, info) {
    state.teacherInfo = info;
  },
  set_bnotes(state, info) {
    state.bnotes = info;
  },
  set_selectTree(state, info) {
    state.selectTree = info;
  },
  set_craigPlay(state, info) {
    state.craigPlay = info;
  },
  set_livingInfo(state, info) {
    state.livingInfo = info;
  },
  set_dialogobj4(state, obj) {
    state.dialogObj4 = Object.assign({}, state.dialogObj4, obj);
  },
  set_tipTime(state, info) {
    state.tipTime = info;
  },
  set_isguestmessage(state, info) {
    state.isguestmessage = info;
  },
  set_userPwd(state, info) {
    state.userPwd = info;
  },
  set_controlTimer(state, info) {
    state.controlTimer = info;
  },
  set_displayNum(state, info) {
    state.displayNum = info;
  },
  set_distributecustomer(state, info) {
    state.distributecustomer = info;
  },
  set_showRed(state, info) {
    state.showRed = info;
  },
  set_permissions(state, info) {
    state.permissions = info;
  },
  set_newRoomHistory(state, info) {
    state.newRoomHistory = info;
  },
  set_rightMenu(state, info) {
    state.rightMenu = info;
  },
  set_timeTable(state, info) {
    state.timeTable = info;
  },
  set_timeTableContent(state, info) {
    state.timeTableContent = info;
  },
  set_noSpeak(state, info) {
    state.noSpeak = info;
  },
  set_isVip(state, info) {
    state.isVip = info;
  },
  set_vipRoom(state, info) {
    state.vipRoom = info;
  },
  set_pwdRight(state, info) {
    state.pwdRight = info;
  },
  set_packOrDevelop(state, info) {
    state.packOrDevelop = info;
  },
  set_isReload(state, info) {
    state.isReload = info;
  },
  set_isComming(state, info) {
    state.isComming = info;
  },
  set_controlNoLive(state, info) {
    state.controlNoLive = info;
  },
  set_teacherPlayBack(state, info) {
    state.teacherPlayBack = info;
  },
  set_customMsgTotal(state, info) {
    state.customMsgTotal = info;
  },
};

const actions = {
  // 获取房间列表
  async getRoomList({ commit, rootState }, params) {
    const res = await getRoomList(params);
    if (res.Status === 0) {
      const list = [];
      res.content &&
        res.content.length &&
        res.content.forEach((item) => {
          if (item.bclass) {
            getRoomInfo({
              userId: rootState.login.userInfo.id || "",
              "broom.id": item.id,
              "bclass.type": 1,
            }).then((info) => {
              item.regStatus = info.regStatus;
              if (item.enabled) {
                list.push(item);
              }
            });
          } else {
            item.regStatus = "";
            if (item.enabled) {
              list.push(item);
            }
          }
        });
      commit("set_roomList", list);
    }
  },
  // craig -- 获取课程信息和讲师信息
  async getTeacherInfo({ commit }, params) {
    const res = await getTeacherInfo(params);
    if (res.Status === 0 && res.bsheet) {
      commit("set_teacherInfo", [res.bsheet, res.teacher]);
    } else {
      // Message({
      //   type: 'warning',
      //   message: res.message,
      //   duration:0,
      //   showClose:true,
      // })
    }
  },
  // craig -- 获取课堂笔记列表
  async getBnotes({ commit }, params) {
    const res = await getBnotes(params);
    if (res.Status === 0) {
      if (res.content && res.content.length) {
        commit("set_bnotes", res.content);
      }
    }
  },
  // craig -- 获取正在直播的房间列表
  async getLiving({ commit }, params) {
    const res = await getLiving(params);
    if (res.Status === 0) {
      commit("set_livingInfo", res.content[0]);
    }
  },
  // craig -- 获取登录提示时间间隔
  async loginSetTime({ commit }, params) {
    const res = await loginSetTime(params);
    if (res.Status === 0) {
      const data = {
        num: "",
        minute: "",
      };
      if (res.minute.value.value == 0) {
        commit("set_controlTimer", true);
      } else {
        data.minute = res.minute.value.value;
        data.num = res.num.value.value;
        commit("set_tipTime", data);
      }
    }
  },
  // craig -- 判断游客是否可以发言
  async isguestmessage({ commit }, params) {
    const res = await isguestmessage(params);
    if (res.Status === 0) {
      commit("set_isguestmessage", res.dictfill.value.value);
    }
  },
  // craig -- 获取聊天记录显示条数
  async getDisplayNum({ commit }, params) {
    const res = await getDisplayNum(params);
    if (res.Status === 0) {
      commit("set_displayNum", res.dictfill.value.value);
    }
  },
  // craig -- 分配客服
  async getDistributecustomer({ commit }, params) {
    const res = await getDistributecustomer(params);
    if (res.Status === 0) {
      commit("set_distributecustomer", res.content);
    }
  },
  // craig -- 查询每周课表
  async getTimetable({ commit }, params) {
    const res = await getTimetable(params);
    if (res.Status === 0) {
      commit("set_timeTableContent", res.content);
    }
  },
  // craig -- vip房间密码接口
  async verifypwd({ commit }, params) {
    const res = await verifypwd(params);
    if (res.Status === 0) {
      Message({
        type: "success",
        showClose: true,
        message: res.message,
      });
      commit("set_pwdRight", true);
    } else {
      Message({
        type: "error",
        showClose: true,
        message: res.message,
      });
      commit("set_pwdRight", false);
    }
  },
  // craig -- 新增判断进入房间条件是否满足
  async Iswelcome({ commit }, params) {
    const res = await Iswelcome(params);
    if (res.Status === 0) {
      commit("set_isComming", true);
    } else if (res.Status === 801) {
      commit("set_isComming", true);
    } else {
      commit("set_isComming", false);
      Message({
        type: "error",
        showClose: true,
        message: res.message,
      });
      return;
    }
  },
  // craig -- 获取老师直播回放视频列表
  async getPlayBack({ commit }, params) {
    const res = await getPlayBack(params);
    if (res.Status === 0) {
      commit("set_teacherPlayBack", res.content.length ? res.content : []);
    }
  },
  // craig -- 设置昵称和头像
  async nickAvatar({ commit }, params) {
    const res = await nickAvatar(params);
    if (res.Status === 0) {
      console.log(res.content);
      commit("set_userInfo", res.content);
      setSessionStorage("userInfo", res.content);
      location.reload();
    }
  },
  // 获取指定房间信息
  getRoomInfo({ commit }, params) {
    return getRoomInfo(params);
  },
  // 获取当前房间历史聊天记录
  async getRoomHistoryChatRecord({ commit }, params) {
    const res = await getRoomHistoryChatRecord(params);
    if (res.Status === 0) {
      commit(
        "set_roomhistorylist",
        res.content.length ? res.content.reverse() : []
      );
    }
  },
  // 获取当前房间历史聊天记录 -- craig新增
  async getNewRoomHistory({ commit }, params) {
    const res = await getNewRoomHistory(params);
    if (res.Status === 0) {
      commit("set_newRoomHistory", res.content.length ? res.content : []);
    }
  },
  // 获取当前房间在线用户
  async getCurrentRoomUser({ commit }, params) {
    const res = await getCurrentRoomUser(params);
    if (res.Status === 0) {
      commit("set_currentroomuserlist", res.content);
      commit("set_roomusertotal", res.totalElements);
    }
  },
  // 游客进入直播间
  async youkeLogin({ commit }, params) {
    console.log("youke接口");
    const res = await youkeLogin(params);
    if (res.Status === 0) {
      commit("set_userInfo", res.user);
      commit("set_token", res.token);
      setSessionStorage("token", { token: res.token });
      setSessionStorage("userInfo", res.user);
      location.reload();
    }
  },
  socketInit({ commit, dispatch, state, rootState }, parmas) {
    let deviceType = "pc";
    deviceType = document.body.clientWidth > 500 ? "pc" : "wap";
    const socketurl =
      config.socketUrl + `/${parmas.roomid}/${deviceType}/${parmas.uid}/false`;
    const socket = new WebSocket(socketurl);
    socket.onopen = (res) => {
      console.log("socket连接成功", res);
      commit("set_socket", socket);
    };
    socket.onmessage = async (res) => {
      console.log(res.data, "12222222222222222222222");
      const data = JSON.parse(res.data);
      console.log(data);
      // 直播间聊天信息
      if (data.msgtype === "msg") {
        /**
         * 消息类型type: 11、私聊消息； 12、撤回消息操作； 10、invalid connect param； 101、服务器拒绝登录；201其他设备或地方登录；1001：socket连接成功
         **/
        if (data.type === 11) {
          console.log("私聊消息", data);
          // 如果私聊窗口未打开
          if (!state.dialogObj.dialogVisible) {
            // state.showRed = true;
            commit("set_showRed", true);
            // 统计未读的新消息数
            let num = Number(state.customMsgTotal);
            num++;
            commit("set_customMsgTotal", num);
          }

          // 普通用户逻辑
          if (rootState.login.userInfo.utype == 0) {
            console.log("普通用户发消息了");
            const params = {
              broomid: state.roomInfo.id,
              type: 11,
              id1: rootState.login.userInfo.id,
              id2: state.distributecustomer.id,
              page: 0,
              size: 50,
            };
            // 2、获取两人之间的聊天记录
            await dispatch("getUserPrivateChatHistoryListById", params);
          } else {
            /** 判断私聊窗口状态（打开 || 没打开）**/
            if (state.dialogObj.dialogVisible) {
              // state.showRed = false;
              commit("set_showRed", false);
              commit("set_customMsgTotal", 0);
              // 私聊窗口打开状态
              // 1、私聊窗口已经打开，并且消息属于'当前客服' 和 '当前客服默认聊天的用户' 之间的聊天
              if (
                (rootState.login.userInfo.id === data.sid &&
                  state.privateUserInfo.id === data.rid) ||
                (rootState.login.userInfo.id === data.rid &&
                  state.privateUserInfo.id === data.sid)
              ) {
                const list = Object.assign([], state.privateChatHistoryList);
                list.push(data);
                commit("set_privateChatHistoryList", list);
              } else {
                // 2、私聊窗口已经打开，消息不是'当前客服' 和 '当前客服默认聊天的用户' 之间的聊天，需要判断发送消息的用户（1）用户已经在客服的聊天列表中，就直接在该用户后面显示红点；（2）用户不在客服聊天列表中，将用户添加到私聊列表并在名字后面显示红点
                const list = [...state.privateUserList];
                const listarr = [];
                list.length &&
                  list.forEach((item, index) => {
                    listarr.push(item.id);
                    if (item.id === data.sid) {
                      item.isred = true;
                      // list.splice(index, 1)
                      // list.unshift(item)
                    }
                  });
                if (listarr.indexOf(data.sid) === -1) {
                  list.push({
                    id: data.sid,
                    nick: data.sname,
                    isred: true,
                    mtaccr: data.smtaccr,
                  });
                }
                commit("set_privateUserList", list);
              }
            } else {
              // 私聊窗口没有打开：获取私聊用户列表。并同时把当前发起私聊的客户设置为默认聊天对象放在私聊列表第一位。请求两人之间的私聊历史记录，打开私聊窗口
              // 1、获取用户私聊列表，然后用户去重，添加私聊用户第一个，并设置为默认聊天用户
              await dispatch("getUserListByManagerId", {
                id: rootState.login.userInfo.id,
                broomid: state.roomInfo.id,
              });
              const list = [...state.privateUserList];
              list.length &&
                list.forEach((item, index) => {
                  if (item.id === data.sid) {
                    list.splice(index, 1);
                    list.unshift(item);
                    commit("set_privateUserInfo", item);
                  }
                });
              commit("set_privateUserList", list);
              // 2、获取两人之间的聊天记录
              await dispatch("getUserPrivateChatHistoryListById", {
                broomid: state.roomInfo.id,
                type: 11,
                id1: rootState.login.userInfo.id,
                id2: data.sid,
              });
              // 3、打开聊天窗口
              commit("set_dialogobj", {
                dialogVisible: true,
                isCloseIcon: false,
                type: 3,
                width: "950px",
              });
            }
          }
        } else if (data.type === 12) {
          console.log("撤回消息", data);
          // 撤回操作
          const list = Object.assign([], state.roomHistoryChatRecordList);
          commit("set_roomhistorylist", []);
          list.length &&
            list.forEach((item, index) => {
              if (item.id === data.id) {
                list.splice(index, 1);
                commit("set_roomhistorylist", list);
              }
            });
        } else if (data.type === 13) {
          console.log("错误消息处理", data);
          // 错误消息处理
          Message({
            type: "error",
            showClose: true,
            message: data.param.TXT,
          });
        } else if (data.type === 14) {
          console.log("错误消息处理", data);
          // 错误消息处理
          Message({
            type: "error",
            showClose: true,
            message: data.param.TXT,
          });
        } else if (data.type === 15) {
          console.log("老师上播了");
          // 老师开始直播了，需要将直播间的播放地址改为直播流地址
          // const adddd = state.roomInfo.param.pcurl.replace('https://pull.gieqd.com/','/zhibo/')
          commit("set_ckplayerObject", {
            video: state.roomInfo.param.pcurl,
            live: true,
          });
          // eslint-disable-next-line no-undef
          commit("set_player", new Ckplayer(state.ckplayerObject));
          commit("set_isplay", true);

          // vue-video-player插件，暂时废弃该方法
          // commit('set_videoPlayerConfig', { sources: [{ src: state.roomInfo.param.pcurl, type: 'application/x-mpegURL' }], poster: '' })
        } else if (data.type === 16) {
          console.log("老师下播了");
          // 老师直播结束了，需要将直播间的播放地址改为默认播放地址
          commit("set_isplay", false);
          commit("set_ckplayerObject", {
            video: state.roomInfo.backimg,
            live: false,
          });
          // craig--注释掉原来的逻辑，因为没有 efile_id 走不下去
          state.roomInfo.param.efile_id
            ? await dispatch("getRoomDefaultVideoInfo", {
                id: state.roomInfo.param.efile_id,
                type: 0,
              })
            : "";
        } else {
          if (data.broomid && data.sid) {
            const list = Object.assign([], state.roomHistoryChatRecordList);
            list.push(data);
            commit("set_roomhistorylist", list);
          }
        }
      } else if (data.msgtype === "gift") {
        // 直播间礼物情况
        if (data.broomid) {
          const list = Object.assign([], state.roomGiftList);
          list.push(data);
          commit("set_roomGiftList", list);
        }
      }
    };
    socket.onerror = (event) => {
      console.log("socket接收错误", event);
    };
    socket.onclose = (event) => {
      console.log("socket断开", event);
      if (
        state.roomInfo.id &&
        rootState.login.userInfo.id &&
        event.code !== 1000
      ) {
        dispatch("socketInit", {
          roomid: state.roomInfo.id,
          uid: rootState.login.userInfo.id,
        });
      } else {
        console.log(state.roomInfo.id && rootState.login.userInfo.id);
        console.log("没有房间信息和用户信息，无法重新连接");
      }
    };
  },
  // 获取当前老师或者客服的私聊用户列表（私聊）
  async getUserListByManagerId({ commit, state }, params) {
    const res = await getUserListByManagerId(params);
    if (res.Status === 0) {
      commit("set_privateUserList", res.content);
    } else {
      if (state.ishomePrivateBtn) {
        Message({
          type: "error",
          message: res.message,
        });
      }
      commit("set_privateUserList", []);
    }
  },
  // 获取客服或者老师和默认对象的聊天记录（私聊）
  async getUserPrivateChatHistoryListById({ commit }, params) {
    const res = await getUserPrivateChatHistoryListById(params);
    if (res.Status === 0) {
      commit("set_privateChatHistoryList", res.content.reverse());
    } else {
      commit("set_privateChatHistoryList", []);
    }
  },
  // 禁言操作
  handleForbidEvent({ commit }, params) {
    return handleForbidEvent(params);
  },
  // 获取默房间默认视频信息
  async getRoomDefaultVideoInfo({ commit }, params) {
    const res = await getRoomDefaultVideoInfo(params);
    if (res.Status === 0 && res.content.length) {
      const fileType = res.content[0].fpath
        ? res.content[0].fpath
            .split("?")[0]
            .split(".")
            .pop()
        : "mp4";
      // 获取是视频格式
      const obj = {
        type: "video/" + fileType,
        src: res.content[0].fpath,
      };
      commit("set_defaultVideoInfo", obj);
      commit("set_ckplayerObject", { video: obj.src, live: false });
      // vue-video-player插件，暂时废弃该方法
      // commit('set_videoPlayerConfig', { sources: [obj], poster: res.content[0].fpic })
    }
  },
  // 获取平台字典数据
  async getDicts({ commit }, params) {
    const res = await getDicts(params);
    if (res.Status === 0) {
      const list =
        res.content.length && res.content[0].dictDetails
          ? res.content[0].dictDetails
          : [];
      switch (params.name) {
        case "sys_forbid_time":
          commit("set_forbidtimelist", list);
          break;
        case "sys_forbid_reason":
          commit("set_forbidreasonlist", list);
          break;
        case "talk_set":
          commit("set_roomset", list);
          commit("set_permissions", list);
          break;
        default:
          break;
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
