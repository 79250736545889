import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router';
import App from './App.vue';
import less from "less";
import VueAwesomeSwiper from "vue-awesome-swiper/dist/vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import VueMeta from 'vue-meta'
import $ from 'jquery';
import './assets/icon/iconfont/iconfont.css'
import * as echarts from 'echarts';
import axios from 'axios';
import { Popup } from "vant";
import 'vant/lib/index.css';
import { maidianOpenreal } from "../api/info";
import VueLuckyCanvas from '@lucky-canvas/vue';
import store from './components/subject/classRoom/store'
import VideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css' // videoJs的样式
import 'videojs-contrib-hls' // 引入才能播放m3u8文件
// import 'lib-flexible/flexible.js'

Vue.prototype.$echarts = echarts;
Vue.prototype.axios = axios;


// 使用juqery(挂载)
window.jQuery = $;
window.$ = $;

Vue.use(ElementUI);
Vue.use(less);
Vue.use(VueAwesomeSwiper);
Vue.use(Popup);
Vue.use(VueLuckyCanvas);
Vue.use(VideoPlayer)
Vue.use(VueMeta)
// Vue.prototype.$EventBus = new Vue()

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App),
});

// 日期格式化
Date.prototype.Format = function (fmt) {
  const o = {
    'M+': this.getMonth() + 1, // 月份
    'd+': this.getDate(), // 日
    'h+': this.getHours(), // 小时
    'm+': this.getMinutes(), // 分
    's+': this.getSeconds(), // 秒
    'q+': Math.floor((this.getMonth() + 3) / 3), // 季度
    'S': this.getMilliseconds() // 毫秒
  }
  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length))
  for (let k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
  }
  return fmt
}
// 路由导航守卫==>路由跳转后，回到页面顶部
router.beforeEach((to, from, next) => {
  // chrome
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  // safari
  window.pageYOffset = 0
  next()
})
router.afterEach((to,from)=>{
  // crm数据埋点
  maidianOpenreal({ eventname: 'z1', eventdescribe: '访客数据' , eventType:'visit' , backurl:window.location.origin + '/#' + from.fullPath , currenturl:window.location.origin + '/#' + to.fullPath});
})