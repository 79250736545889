import request from '../../utils/axios';

// 获取App渠道链接
export function getChannelInfo(code) {
  return request({
      url: '/bpis/common/getChannelInfo',
      method: 'get',
      headers : {
        'appcode': code
      }
  })
}

// 根据key值获取配置信息
export function getConfigurationValue(data) {
  return request({
    url:'bpis/common/getJfConfigurationValue',
    method: 'POST',
    data
  })
}