import { setSessionStorage, getSessionStorage } from '../../utils/tool'
import { handleLogin, handleSms, handleRegister, handlePassword, newhandleRegister, newhandleSms } from '../../api/login'
import { Message } from 'element-ui'
const state = {
  token: getSessionStorage('token') ? JSON.parse(getSessionStorage('token')).token : '',
  userInfo: JSON.parse(getSessionStorage('userInfo')) ? JSON.parse(getSessionStorage('userInfo')) : {},
  // 控制显示登陆或注册
  activeName:'second',
  // 是否注册成功
  registerSuccess:false,
  // 是否登录
  isLogin:false,
  // 控制聊天区域显示问题
  chartShowControl:true,
  registerAfterInfo:'',
}

const mutations = {
  set_token(state, token) {
    state.token = token
  },
  set_userInfo(state, info) {
    state.userInfo = info
  },
  set_activeName(state,info){
    state.activeName = info
  },
  set_registerSuccess(state,info){
    state.registerSuccess = info
  },
  set_isLogin(state,info){
    state.isLogin = info
  },
  set_chartShowControl(state,info){
    console.log(info);
    state.chartShowControl = info
  },
  set_registerAfterInfo(state,info){
    state.registerAfterInfo = info
  },
}

const actions = {
  // 登录
  async handleLogin({ commit }, params) {
    if (params.logintype === 0) {
      const res = await handleLogin(params)
      console.log(res.Status)
      if (res.Status === 0) {
        commit('set_userInfo', res.user)
        commit('set_token', res.token)
        setSessionStorage('token', { token: res.token })
        setSessionStorage('userInfo', res.user)
        commit('set_isLogin',true);
        commit('set_chartShowControl',false)
        location.reload()
      } else if (res.Status === 400) {
        Message({
          type: 'error',
          message: res.message
        })
      } else if (res.status === 401) {
        Message({
          type: 'error',
          message: '账号错误'
        })
      } else {
        Message({
          type: 'error',
          message: '登录失败'
        })
      }
    } else {
      const res = await handleLogin(params)
      if (res.Status === 0) {
        if(res.user.nick === '') {
          let name = res.user.mtaccr.toString()
          res.user.nick = name.slice(0, 2) + '****' + name.slice(name.length -2, name.length)
        }
        commit('set_userInfo', res.user)
        commit('set_token', res.token)
        setSessionStorage('token', { token: res.token })
        setSessionStorage('userInfo', res.user)
        commit('set_isLogin',true);
        commit('set_chartShowControl',false)
        location.reload()
      } else if (res.Status === 400) {
        Message({
          type: 'error',
          message: res.message
        })
        // params.uuid = params.password
        // delete params.password
        // const ress = await handleRegister(params)
        // if (ress.Status === 0) {
        //   Message({
        //     type: 'success',
        //     message: '登录成功！'
        //   })
        //   commit('set_userInfo', ress.user)
        //   commit('set_token', ress.token)
        //   setSessionStorage('token', { token: ress.token })
        //   setSessionStorage('userInfo', ress.user)
        //   location.reload()
        // } else if (ress.Status === 400) {
        //   Message({
        //     type: 'error',
        //     message: ress.message
        //   })
        // }
      } else if (res.status === 401) {
        Message({
          type: 'error',
          message: '账号错误'
        })
      } else {
        Message({
          type: 'error',
          message: '登录失败'
        })
      }
    }
  },
  async handleSms({ commit }, params) {
    const res = await handleSms(params)
    console.log(res)
    if (res.Status === 0) {
      Message({
        type: 'success',
        message: '发送成功！'
      })
    } else if (res.Status === 400) {
      Message({
        type: 'error',
        message: res.message
      })
    }
  },
  async handleRegister({ commit }, params) {
    const res = await handleRegister(params)
    if (res.Status === 0) {
      commit('set_userInfo', res.user)
      commit('set_token', res.token)
      commit('set_registerSuccess', true)
      setSessionStorage('token', { token: res.token })
      setSessionStorage('userInfo', res.user)
      Message({
        type: 'success',
        message: res.message
      })
    } else if (res.Status === 400) {
      Message({
        type: 'error',
        message: res.message
      })
    }
  },
  // craig--新增注册
  async newhandleRegister({ commit, dispatch }, params){
    const res = await newhandleRegister(params)
    if(res.ok == 1){
      Message({
        message: res.msg,
        type: 'success',
      });
      const option = {
        username: res.data.Account,
        password: res.data.Password,
        device: 'pc',
        signtype: 10,
        code:'',
        conner:true,
        plats:[{"id":1}],
      }
      commit('set_registerAfterInfo',option);
      commit('set_registerSuccess', true)
    }else{
      Message({
        message: res.msg,
        type: 'error',
      });
    }
  },
  // craig--新增验证码
  async newhandleSms({ commit }, params){
    const res = await newhandleSms(params)
    console.log(res);
  },
  async handlePassword({ commit }, params) {
    const res = await handlePassword(params)
    return res
    // if (res.Status === 0) {
    //   Message({
    //     type: 'success',
    //     message: res.message
    //   })
    // } else if (res.Status === 400) {
    //   Message({
    //     type: 'error',
    //     message: res.message
    //   })
    // }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
