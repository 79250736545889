<template>
  <div class="contentUs">
    <div class="cuBox">
      <ul>
        <li>
          <img src="../../static/common/email.png" alt="" />
          <p>
            <span>客服邮箱</span>
            <span v-if="control">cs@rlcvipltd.net</span>
            <span v-else>CS@xsintlg.com</span>
          </p>
        </li>
        <li v-if="control">
          <img src="../../static/common/localhost.png" alt="" />
          <p>
            <span>公司地点</span>
            <span>香港九龙尖沙咀</span>
          </p>
        </li>
        <li v-if="!control">
          注册地址: Flat 1511, 15/F, Beverley Commercial Centre, 87 Chatham Road
          South, Tsimshatsui, Kowloon, Hong Kong<br />
          营运地址: Flat 11 15/F Beverley Commercial Centre, 87-105 Chatham Road
          South, Tsimshatsui, Kowloon, Hong Kong
        </li>
        <li>
          <img src="../../static/common/phone.png" alt="" />
          <p>
            <span>联系电话</span>
            <span v-if="control">(852) 23919622</span>
            <span v-else>+852 23927848</span>
          </p>
        </li>
      </ul>
      <div class="levelLine"></div>
      <div class="tips">
        <ul>
          <li>温馨提示：市场有风险，投资需谨慎，最终受益以实际为准</li>
          <li>应用名称：红狮</li>
          <li @click="checkPower(1)" style="cursor: pointer;">
            应用权限：查看权限
          </li>
          <li>更新时间：2024年09月02日</li>
        </ul>
        <ul>
          <li>APP开发者：香港红狮集团有限公司</li>
          <li>应用版本：5.0.0</li>
          <li @click="checkPower(2)" style="cursor: pointer;">
            隐私权限：查看权限
          </li>
          <li>香港红狮集团有限公司是香港金银业贸易场AA类行员，行员编号：081</li>
          <li v-if="!control">香港公司注册处的公司编号：1448678</li>
        </ul>
        <ul v-if="!control">
          <li>受限地區：</li>
          <li>
            香港红狮集团有限公司不為某些司法管轄區的公民/居民提供服務，例如 加拿大、古巴、伊朗、伊拉克、日本、緬甸、朝鮮、蘇丹、敘利亞、土耳其、美國。
          </li>
        </ul>
      </div>
      <div class="copyRight">COPYRIGHT(©) 2023 红狮集团</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contactus",
  data() {
    return {
      control: true,
    };
  },
  created() {
    if (window.location.origin.indexOf("xsintlg") !== -1) {
      this.control = false;
    }
  },
  methods: {
    checkPower(attr) {
      if (attr == 1) {
        this.$router.push({
          name: "Application",
        });
      } else {
        this.$router.push({
          name: "Privacy",
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.contentUs {
  width: 100%;
  height: 470px;
  background-color: #121212;
  color: #616161;
  background: url("../../static/common/bottom.png") no-repeat;
  background-size: cover;
  .cuBox {
    // width: 1416px;
    height: 470px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    box-sizing: border-box;
    padding-top: 30px;
    ul {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      padding: 0 80px;
      li {
        display: flex;
        justify-content: center;
        align-items: center;
        p {
          margin-left: 20px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          color: #676566;
          span {
            line-height: 30px;
          }
          span:nth-child(1) {
            font-weight: 600;
            font-size: 14px;
          }
          span:nth-child(2) {
            font-size: 13px;
          }
        }
      }
    }
    .levelLine {
      width: 100%;
      height: 1px;
      background-color: #e2e2e4;
      margin: 15px auto 20px;
    }
    .tips {
      width: 100%;
      box-sizing: border-box;
      padding: 0 80px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      ul {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 0;
        li {
          line-height: 35px;
          font-size: 14px;
          color: #b4b4bc;
        }
      }
    }
    .copyRight {
      text-align: center;
      font-size: 13px;
      margin: 15px 0 30px 0;
      color: #b4b4bc;
    }
  }
}
@media screen and (max-width: 1600px) {
  .contentUs .cuBox[data-v-32f213be] {
    // width: 1310px;
  }
}
@media screen and (max-width: 500px) {
  .contentUs {
    height: auto;
    background: none;
    background-color: #f0f2fd;
    .cuBox {
      width: 100% !important;
      height: auto;
      ul {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        box-sizing: border-box;
        padding-left: 30px;
        li {
          height: 55px;
          img {
            width: 30px;
          }
          p {
            span {
              line-height: 20px;
            }
          }
        }
      }
      .levelLine {
        width: 90%;
      }
      .tips {
        // padding: 0 0 100px 0;
        width: 90%;
        margin: 0 auto;
        flex-direction: column;
        ul {
          li {
            height: auto;
          }
        }
      }
    }
  }
}
</style>
