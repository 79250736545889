import request from '../utils/request'

// 获取房间列表
export function getRoomList(params) {
  return request({
    url: '/Common/brooms',
    method: 'get',
    params
  })
}
// 获取指定房间的信息
export function getRoomInfo(params) {
  return request({
    url: '/Common/brooms/info',
    method: 'get',
    params
  })
}
// 获取当前房间中的用户列表（在线用户）
export function getCurrentRoomUser(params) {
  return request({
    url: '/Common//websock/room/users',
    method: 'get',
    params
  })
}
// 游客进入房间
export function youkeLogin(params) {
  return request({
    url: '/auth/login',
    method: 'post',
    data: params
  })
}
// 获取当前房间的聊天记录
export function getRoomHistoryChatRecord(params) {
  return request({
    url: '/Common/bmsgs',
    method: 'get',
    params
  })
}
// 获取当前房间的聊天记录 -- craig新增
export function getNewRoomHistory(params) {
  return request({
    url: '/Common/bmsgs',
    method: 'get',
    params
  })
}
// 获取当前老师或者客服的私聊用户列表
export function getUserListByManagerId(params) {
  return request({
    url: '/api/privatechatusers',
    method: 'get',
    params
  })
}
// 禁言操作
export function handleForbidEvent(data) {
  return request({
    url: '/api/fusers',
    method: 'post',
    data
  })
}
// 获取当前登录帐号和指定用户之间的私聊聊天记录
export function getUserPrivateChatHistoryListById(params) {
  return request({
    url: '/api/bmsgs',
    method: 'get',
    params
  })
}
// 获取默房间默认视频信息
export function getRoomDefaultVideoInfo(params) {
  return request({
    url: '/Common/efiles',
    method: 'get',
    params
  })
}
// 获取项目字典
export function getDicts(params) {
  return request({
    url: '/Common/dicts',
    method: 'get',
    params
  })
}
// craig--根据直播间号获取课程信息和讲师信息
export function getTeacherInfo(params){
  return request({
    url: '/Common/bsheetone',
    method: 'get',
    params
  })
}
// craig--获取课堂笔记列表
export function getBnotes(params){
  return request({
    url: '/Common/bnotes',
    method: 'get',
    params
  })
}
// craig--获取正在直播的房间列表
export function getLiving(params){
  return request({
    url: '/Common/brooms/living',
    method: 'get',
    params
  })
}
// craig--登录提示时间间隔
export function loginSetTime(params){
  return request({
    url: '/Common/loginsettime',
    method: 'get',
    params
  })
}
// craig--登录提示时间间隔
export function isguestmessage(params){
  return request({
    url: '/Common/isguestmessage',
    method: 'get',
    params
  })
}
// craig--获取聊天记录显示条数
export function getDisplayNum(params){
  return request({
    url: '/Common/pagesize',
    method: 'get',
    params
  })
}
// craig--分配客服
export function getDistributecustomer(params){
  return request({
    url: '/api/distributecustomer',
    method: 'get',
    params
  })
}
// craig--查询每周课表
export function getTimetable(params){
  return request({
    url: '/Common/bsheets',
    method: 'get',
    params
  })
}
// craig--vip房间密码验证
export function verifypwd(data){
  return request({
    url: '/Common/verifypwd',
    method: 'post',
    data
  })
}
// craig--新增判断进入房间条件是否满足
export function Iswelcome(data){
  return request({
    url: '/Common/iswelcome',
    method: 'post',
    data
  })
}
// craig--获取老师直播回放视频列表
export function getPlayBack(params){
  return request({
    url: '/Common/efiles',
    method: 'get',
    params
  })
}
// 直播房间报名接口
export function roomSignUp(data){
  return request({
    url: '/api/myclass',
    method: 'post',
    data
  })
}
// craig--设置昵称和头像
export function nickAvatar(data){
  return request({
    url: '/api/mentors',
    method: 'put',
    data
  })
}
// craig--获取老师列表
export function getTeacher(params){
  return request({
    url: '/Common/teachlist',
    method: 'get',
    params
  })
}
// craig--获取榜单排行
export function getGiftList(params){
  return request({
    url: '/Common/giftlist',
    method: 'get',
    params
  })
}
// 获取直播间礼物列表
export function getPrezzies(params){
  return request({
    url: '/Common/gifts',
    method: 'get',
    params
  })
}
// 直播间送礼物
export function giveGiftList(data){
  return request({
    url: '/Common/givegift',
    method: 'post',
    data
  })
}
// 直播间送礼明细
export function GetGiveGiftList(params){
  return request({
    url: '/Common/givegift',
    method: 'get',
    params
  })
}
// 获取用户积分信息
export function getUserIntegralData(params){
  return request({
    url: '/Common/getpoint',
    method: 'get',
    params
  })
}
// 获取直播间轮播图
export function getSwiperList(params){
  return request({
    url: '/Common/rotate/picture/list?platid=1',
    method: 'get',
    params
  })
}