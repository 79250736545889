import Vue from 'vue'
import Vuex from 'vuex'
import createVuexLogger from 'vuex/dist/logger'

// 不同模块引入（start）
import login from './modules/login'
import home from './modules/home'
import common from './modules/common'
// 不同模块引入（end）

Vue.use(Vuex)
const debug = process.env.NODE_ENV === 'development'

export default new Vuex.Store({
  modules: {
    login,
    home,
    common,
  },
  plugins: debug ? [createVuexLogger()] : []
})
