import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/',
      name: 'Index',
      component: () => import('../components/Index'),
      children: [
        {
          path: 'gif',
          name: 'gif',
          component: () => import('../components/index/gif'),
        },
        {
          path: 'roll',
          name: 'roll',
          component: () => import('../components/index/roll'),
        },
        {
          path: 'tit',
          name: 'tit',
          component: () => import('../components/index/tit'),
        },
        {
          path: 'amDownload',
          name: 'amDownload',
          component: () => import('../components/index/amDownload'),
        },
        {
          path: 'dataCenter',
          name: 'dataCenter',
          component: () => import('../components/index/dataCenter'),
          children: [
            {
              path: 'pastCharts',
              name: 'pastCharts',
              component: () => import('../components/index/echarts/pastCharts'),
            },
            {
              path: 'pastChartsWap',
              name: 'pastChartsWap',
              component: () => import('../components/index/echarts/pastChartsWap'),
            },
          ],
        },
        {
          path: 'information',
          name: 'information',
          component: () => import('../components/index/information'),
        },
        {
          path: 'media',
          name: 'media',
          component: () => import('../components/index/media'),
        },
      ],
    },
    {
      path: '/Details',
      name: 'Details',
      component: () => import('../components/Details'),
      children: [
        {
          path: 'dEcharts',
          name: 'dEcharts',
          component: () => import('../components/details/dEcharts'),
        },
        {
          path: 'detaEcharts',
          name: 'detaEcharts',
          component: () => import('../components/details/detaEcharts'),
        },
      ],
    },
    {
      path: '/layout',
      name: 'layout',
      component: () => import('../components/Layout'),
    },
    {
      path: '/contactus',
      name: 'contactus',
      component: () => import('../components/Contactus'),
    },
    {
      path: '/Aboutus',
      name: 'Aboutus',
      component: () => import('../components/Aboutus'),
    },
    {
      path: '/Appdownload',
      name: 'Appdownload',
      component: () => import('../components/Appdownload'),
    },
    {
      path: '/Market',
      name: 'Market',
      component: () => import('../components/Market'),
      children: [
        {
          path: 'calendar',
          name: 'calendar',
          component: () => import('../components/market/calendar'),
        },
        {
          path: 'news',
          name: 'news',
          component: () => import('../components/market/news'),
        },
        {
          path: 'fast',
          name: 'fast',
          component: () => import('../components/market/fast'),
        },
        {
          path: 'expert',
          name: 'expert',
          component: () => import('../components/market/expert'),
        },
      ],
    },
    {
      path: '/Product',
      name: 'Product',
      component: () => import('../components/Product'),
      children: [
        {
          path: 'productFirst',
          name: 'productFirst',
          component: () => import('../components/product/productFirst'),
        },
        {
          path: 'productSecond',
          name: 'productSecond',
          component: () => import('../components/product/productSecond'),
        },
        {
          path: 'productThird',
          name: 'productThird',
          component: () => import('../components/product/productThird'),
        },
        {
          path: 'productFourth',
          name: 'productFourth',
          component: () => import('../components/product/productFourth'),
        },
      ],
    },
    {
      path: '/Notice',
      name: 'Notice',
      component: () => import('../components/Notice'),
    },
    {
      path: '/Qrcode',
      name: 'Qrcode',
      component: () => import('../components/Qrcode'),
    },
    {
      path: '/Application',
      name: 'Application',
      component: () => import('../components/Application'),
    },
    {
      path: '/Privacy',
      name: 'Privacy',
      component: () => import('../components/Privacy'),
    },
    {
      path: '/NoticeDetails',
      name: 'NoticeDetails',
      component: () => import('../components/NoticeDetails'),
    },
    {
      path: '/newsDetails',
      name: 'newsDetails',
      component: () => import('../components/market/newsDetails'),
    },
    {
      path: '/expertDetails',
      name: 'expertDetails',
      component: () => import('../components/market/expertDetails'),
    },

    {
      path: '/subject/promotion-7a/index',
      name: 'promotion-7a-pc',
      component: () => import('../components/subject/promotion-7a/index'),
    },
    {
      path: '/subject/autumnOct/index',
      name: 'autumnOctPC',
      component: () => import('../components/subject/autumnOct/index'),
    },
    {
      path: '/subject/czzj_6357/index',
      name: 'czzj_6357',
      component: () => import('../components/subject/czzj_6357/index'),
    },
    {
      path: '/subject/czzj_6357/wap',
      name: 'czzj_6357_wap',
      component: () => import('../components/subject/czzj_6357/wap'),
    },
    {
      path: '/subject/Ldx/index',
      name: 'LdxPC',
      component: () => import('../components/subject/Ldx/index'),
    },
    {
      path: '/subject/feinong/index',
      name: 'feinongPC',
      component: () => import('../components/subject/feinong/index'),
    },
    {
      path: '/subject/feinong/wap',
      name: 'feinongWap',
      component: () => import('../components/subject/feinong/wap'),
    },
    {
      path: '/subject/feinongs/index',
      name: 'feinongPCs',
      component: () => import('../components/subject/feinongs/index'),
    },
    {
      path: '/subject/feinongs/wap',
      name: 'feinongWaps',
      component: () => import('../components/subject/feinongs/wap'),
    },
    {
      path: '/subject/Ldx/wap',
      name: 'LdxWap',
      component: () => import('../components/subject/Ldx/wap'),
    },
    {
      path: '/subject/autumnOct/wap',
      name: 'autumnOctWap',
      component: () => import('../components/subject/autumnOct/wap'),
    },
    {
      path: '/policy/policyWft',
      name: 'policyWft',
      component: () => import('../components/policy/policyWft'),
    },
    {
      path: '/policy/policyWft2',
      name: 'policyWft2',
      component: () => import('../components/policy/policyWft2'),
    },
    {
      path: '/policy/policyTsft',
      name: 'policyTsft',
      component: () => import('../components/policy/policyTsft'),
    },
    {
      path: '/policy/policyTsft2',
      name: 'policyTsft2',
      component: () => import('../components/policy/policyTsft2'),
    },
    {
      path: '/policy/policyTsft3',
      name: 'policyTsft3',
      component: () => import('../components/policy/policyTsft3'),
    },
    {
      path: '/policy/policyTsft4',
      name: 'policyTsft4',
      component: () => import('../components/policy/policyTsft4'),
    },
    {
      path: '/subject/worldCup/index',
      name: 'worldCupWeb',
      component: () => import('../components/subject/worldCup/index'),
    },
    {
      path: '/subject/worldCup/wap',
      name: 'worldCupWap',
      component: () => import('../components/subject/worldCup/wap'),
    },
    {
      path: '/subject/live/index',
      name: 'livePc',
      component: () => import('../components/subject/live/index'),
    },
    {
      path: '/subject/live/wap',
      name: 'liveWap',
      component: () => import('../components/subject/live/wap'),
    },
    {
      path: '/subject/classRoom/index',
      name: 'classRoom',
      component: () => import('../components/subject/classRoom/index'),
    },
    {
      path: '/subject/classRoom/indexWap',
      name: 'classRoomWap',
      component: () => import('../components/subject/classRoom/indexWap'),
    },
    {
      path: '/subject/newYear/index',
      name: 'newYear',
      component: () => import('../components/subject/newYear/index'),
    },
    {
      path: '/subject/newYear/wap',
      name: 'newYearWap',
      component: () => import('../components/subject/newYear/wap'),
    },
    {
      path: '/subject/system/index',
      name: 'system',
      component: () => import('../components/subject/system/index'),
    },
    {
      path: '/subject/system/wap',
      name: 'systemWap',
      component: () => import('../components/subject/system/wap'),
    },
    {
      path: '/emailModal/email',
      name: 'email',
      component: () => import('../components/emailModal/email'),
    },
    {
      path: '/subject/report/index',
      name: 'reportPC',
      component: () => import('../components/subject/report/index'),
    },
    {
      path: '/subject/report/wap',
      name: 'reportWap',
      component: () => import('../components/subject/report/wap'),
    },
    {
      path: '/subject/newReport/index',
      name: 'newReportPC',
      component: () => import('../components/subject/newReport/index'),
    },
    {
      path: '/subject/newReport/wap',
      name: 'newReportWap',
      component: () => import('../components/subject/newReport/wap'),
    },
    {
      path: '/subject/tg_9654/index',
      name: 'tg_9654_PC',
      component: () => import('../components/subject/tg_9654/index'),
    },
    {
      path: '/subject/tg_9654/wap',
      name: 'tg_9654_WAP',
      component: () => import('../components/subject/tg_9654/wap'),
    },
    {
      path: '/Disclaimers',
      name: 'Disclaimers',
      component: () => import('../components/Disclaimers'),
    },
    {
      path: '/subject/live2.0/index',
      name: 'live2.0Pc',
      component: () => import('../components/subject/live2.0/index'),
    },
    {
      path: '/subject/live2.0/wap',
      name: 'live2.0Wap',
      component: () => import('../components/subject/live2.0/wap'),
    },
    {
      path: '/subject/invest/index',
      name: 'investPc',
      component: () => import('../components/subject/invest/index'),
    },
    {
      path: '/subject/invest/wap',
      name: 'investWap',
      component: () => import('../components/subject/invest/wap'),
    },
    {
      path: '/subject/mayPage/index',
      name: 'mayPagePc',
      component: () => import('../components/subject/mayPage/index'),
    },
    {
      path: '/subject/mayPage/wap',
      name: 'mayPageWap',
      component: () => import('../components/subject/mayPage/wap'),
    },
    {
      path: '/subject/answer/index',
      name: 'answerPc',
      component: () => import('../components/subject/answer/index'),
    },
    {
      path: '/subject/answer/wap',
      name: 'answerWap',
      component: () => import('../components/subject/answer/wap'),
    },
    {
      path: '/subject/answer_8654/red/index',
      name: 'answer_8654_red_pc',
      component: () => import('../components/subject/answer_8654/red/index'),
    },
    {
      path: '/subject/answer_8654/red/wap',
      name: 'answer_8654_red_wap',
      component: () => import('../components/subject/answer_8654/red/wap'),
    },
    {
      path: '/subject/answer_8654/orange/index',
      name: 'answer_8654_orange_pc',
      component: () => import('../components/subject/answer_8654/orange/index'),
    },
    {
      path: '/subject/answer_8654/orange/wap',
      name: 'answer_8654_orange_wap',
      component: () => import('../components/subject/answer_8654/orange/wap'),
    },
    {
      path: '/subject/answer_8654/yellow/index',
      name: 'answer_8654_yellow_pc',
      component: () => import('../components/subject/answer_8654/yellow/index'),
    },
    {
      path: '/subject/answer_8654/yellow/wap',
      name: 'answer_8654_yellow_wap',
      component: () => import('../components/subject/answer_8654/yellow/wap'),
    },
    {
      path: '/subject/yx_7525/index',
      name: 'yx_7525',
      component: () => import('../components/subject/yx_7525/index'),
    },
    {
      path: '/subject/yx_7525/wap',
      name: 'yx_7525_wap',
      component: () => import('../components/subject/yx_7525/wap'),
    },
    {
      path: '/subject/yx_0821/index',
      name: 'yx_0821',
      component: () => import('../components/subject/yx_0821/index'),
    },
    {
      path: '/subject/yx_0821/wap',
      name: 'yx_0821_wap',
      component: () => import('../components/subject/yx_0821/wap'),
    },
    {
      path: '/subject/august/index',
      name: 'august',
      component: () => import('../components/subject/august/index'),
    },
    {
      path: '/subject/august/wap',
      name: 'augustWap',
      component: () => import('../components/subject/august/wap'),
    },
    {
      path: '/subject/lsy-8901/index',
      name: 'lsy-8901',
      component: () => import('../components/subject/lsy-8901/index'),
    },
    {
      path: '/subject/lsy-8901/wap',
      name: 'lsy-8901-wap',
      component: () => import('../components/subject/lsy-8901/wap'),
    },
    {
      path: '/subject/newYear9911/index',
      name: 'newYear9911',
      component: () => import('../components/subject/newYear9911/index'),
    },
    {
      path: '/subject/newYear9911/wap',
      name: 'newYear9911wap',
      component: () => import('../components/subject/newYear9911/wap'),
    },
    {
      path: '/subject/sc_2024/index',
      name: 'sc_2024',
      component: () => import('../components/subject/sc_2024/index'),
    },
    {
      path: '/subject/sc_2024/wap',
      name: 'sc_2024wap',
      component: () => import('../components/subject/sc_2024/wap'),
    },
    {
      path: '/subject/authentication/index',
      name: 'authentication',
      component: () => import('../components/subject/authentication/index'),
    },
    {
      path: '/subject/authentication/futong',
      name: 'authenticationFutong',
      component: () => import('../components/subject/authentication/futong.vue'),
    },
    {
      path: '/subject/turntable/index',
      name: 'turntable',
      component: () => import('../components/subject/turntable/index'),
    },
    {
      path: '/subject/turntable/wap',
      name: 'turntableWap',
      component: () => import('../components/subject/turntable/wap'),
    },
    {
      path: '/subject/traderCompetition/index',
      name: 'traderCompetitionPc',
      component: () => import('../components/subject/traderCompetition/index'),
    },
    {
      path: '/subject/traderCompetition/wap',
      name: 'traderCompetitionWap',
      component: () => import('../components/subject/traderCompetition/wap'),
    },
    {
      path: '/subject/scratcher',
      name: 'scratcher',
      component: () => import('../components/subject/scratcher/index'),
    },
    {
      path: '/subject/registerSuccess',
      name: 'scratcher',
      component: () => import('../components/subject/registerSuccess/index'),
    },
  ],
});
