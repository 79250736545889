<template>
  <div>
    <div class="humanService" id="draggable" v-if="isAICustomService"></div>
  </div>
  
</template>

<script>
  import { mapState  } from "vuex";
  export default {
    data() {
      return {
        isDragging: false,
        startX: 0,
        startY: 0,
        offsetX: 0,
        offsetY: 0,
      }
    },
    computed: {
      ...mapState('common', {
        isAICustomService: 'isAICustomService',
      }),
      styleObject() {
        return {
          position: 'absolute',
          left: `${this.offsetX}px`,
          top: `${this.offsetY}px`,
          cursor: this.dragging ? 'grabbing' : 'grab',
        };
      },
    },
    watch: {
      isAICustomService: {
        handler(newVal) {
          if(newVal) {
            setTimeout(() => {
              this.moveHumanService();
            }, 500)
          }
        },
        immediate: true,
      },
    },
    
    methods: {
      moveHumanService() {
        const draggable = document.getElementById('draggable');
        let isDragging = false;
        let startX, startY;
        const threshold = 5; // 拖动阈值，单位为像素

        function startDrag(e) {
          isDragging = false;
          startX = e.clientX || e.touches[0].clientX;
          startY = e.clientY || e.touches[0].clientY;

          document.addEventListener('mousemove', onMouseMove);
          document.addEventListener('mouseup', onMouseUp);
          document.addEventListener('touchmove', onTouchMove, { passive: false });
          document.addEventListener('touchend', onTouchEnd);
        }

        function onMouseMove(e) {
          const moveX = e.clientX - startX;
          const moveY = e.clientY - startY;

          if (Math.abs(moveX) > threshold || Math.abs(moveY) > threshold) {
              isDragging = true;
              const x = e.clientX - (draggable.offsetWidth / 2);
              const y = e.clientY - (draggable.offsetHeight / 2);
              draggable.style.left = `${x}px`;
              draggable.style.top = `${y}px`;
          }
        }

        function onTouchMove(e) {
          e.preventDefault(); // 阻止默认滚动行为
          const touch = e.touches[0];
          const moveX = touch.clientX - startX;
          const moveY = touch.clientY - startY;

          if (Math.abs(moveX) > threshold || Math.abs(moveY) > threshold) {
              isDragging = true;
              const x = touch.clientX - (draggable.offsetWidth / 2);
              const y = touch.clientY - (draggable.offsetHeight / 2);
              draggable.style.left = `${x}px`;
              draggable.style.top = `${y}px`;
          }
        }

        function onMouseUp(e) {
          document.removeEventListener('mousemove', onMouseMove);
          document.removeEventListener('mouseup', onMouseUp);
          document.removeEventListener('touchmove', onTouchMove);
          document.removeEventListener('touchend', onTouchEnd);

          if (!isDragging) {
              // 执行点击事件的代码
              window.open('https://maxkb.ai-mchat.com/ui/chat/e95c20e02a32f0f8')
          }
        }

        function onTouchEnd(e) {
          document.removeEventListener('mousemove', onMouseMove);
          document.removeEventListener('mouseup', onMouseUp);
          document.removeEventListener('touchmove', onTouchMove);
          document.removeEventListener('touchend', onTouchEnd);

          if (!isDragging) {
            // 执行点击事件的代码
            window.open('https://maxkb.ai-mchat.com/ui/chat/e95c20e02a32f0f8')
          }
        }
        // 为适配PC和移动，写了2套方法
        draggable.addEventListener('mousedown', startDrag);
        draggable.addEventListener('touchstart', startDrag, { passive: false });
      }
    }
  }
</script>

<style lang="less" scoped>
  .humanService {
    position: fixed;
    bottom: 40px;
    right: 15px;
    z-index: 999;
    cursor: pointer;
    width: 150px;
    height: 150px;
    user-select: none; /* 禁止文本选择 */
    background: url('../../../static/common/humanService.png') no-repeat;
    background-size: 100% auto;
  }
  @media screen and (max-width: 500px) {
    .humanService {
      width: 6rem;
      height: 6rem;
      top: 65%;
      right: 0px;
    }
  }
</style>