import axios from 'axios'
import { getSessionStorage } from './tool'

const service = axios.create({
  baseURL: '/apis',
  timeout: 5000
})
// 发起请求
service.interceptors.request.use(
  config => {
    // 请求前设置token
    config.headers['Authorization'] = getSessionStorage('token') ? JSON.parse(getSessionStorage('token')).token : ''
    return config
  },
  error => {
    // 请求错误
    return Promise.reject(error)
  }
)

// 请求返回
service.interceptors.response.use(
  response => {
    const res = response.data
    return res
  },
  error => {
    // 返回错误提示
    console.log('错误提示', error.response)
    if (error && error.response && error.response.status === 400) {
      return error.response.data
    } else if (error && error.response && error.response.status === 401) {
      return error.response.data
    }
  }
)

export default service
